@import '../../@framework/components/Button/button.component.less';

.button-global-variables() {
                  .global-variables();
                  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @PaddingBottom: @input-padding-v;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: none;
  @BorderLeftStyle: none;
  @BorderRightStyle: none;
  @BorderTopStyle: none;
  @MarginRight: 0.25rem;
  @PaddingLeft: 1rem;
  @PaddingRight: 1rem;

                }

                .zapp-button  {
                  .button-global-variables();
                  
                    @BackgroundColor: @color-default;
  @ForegroundColor: @color-default-contrast;
  @HoverBackgroundColor: @color-default-hover;

                    @FontSize: @font-med;

                  .button-status-mixin();
}

.button-status-default {
  .global-variables();
  @BackgroundColor: @color-default;
  @ForegroundColor: @color-default-contrast;
  @HoverBackgroundColor: @color-default-hover;



  .button-status-mixin();
}

.button-status-primary {
  .global-variables();
  @BackgroundColor: @color-primary;
  @ForegroundColor: @color-primary-contrast;
  @HoverBackgroundColor: @color-primary-hover;



  .button-status-mixin();
}

.button-status-info {
  .global-variables();
  @BackgroundColor: @color-info;
  @ForegroundColor: @color-info-contrast;
  @HoverBackgroundColor: @color-info-hover;



  .button-status-mixin();
}

.button-status-success {
  .global-variables();
  @BackgroundColor: @color-success;
  @ForegroundColor: @color-success-contrast;
  @HoverBackgroundColor: @color-success-hover;



  .button-status-mixin();
}

.button-status-warning {
  .global-variables();
  @BackgroundColor: @color-warning;
  @ForegroundColor: @color-warning-contrast;
  @HoverBackgroundColor: @color-warning-hover;



  .button-status-mixin();
}

.button-status-danger {
  .global-variables();
  @BackgroundColor: @color-danger;
  @ForegroundColor: @color-danger-contrast;
  @HoverBackgroundColor: @color-danger-hover;



  .button-status-mixin();
}

.button-size-xs {
  .global-variables();
  @FontSize: @font-xs;



  .button-size-mixin();
}

.button-size-sm {
  .global-variables();
  @FontSize: @font-sm;



  .button-size-mixin();
}

.button-size-med {
  .global-variables();
  @FontSize: @font-med;



  .button-size-mixin();
}

.button-size-lg {
  .global-variables();
  @FontSize: @font-lg;



  .button-size-mixin();
}

.button-size-xl {
  .global-variables();
  @FontSize: @font-xl;



  .button-size-mixin();
}


