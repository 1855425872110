@import "../../../@theming/mixings/guards.less";
@import "../../../@theming/mixings/shorthands.less";

/*.hidden.card-footer {
  display: none;
}

.hidden.card-body {
  display: none;
}*/

.panel-status-mixin () {

  .p-panel {
    .background-color(@BackgroundColor);
    .shadow(@Shadow);
    .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
    .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);
    .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
    .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
    .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);

    .p-panel-toggler {
      .opacity(@ToggleIconOpacity);

      > span {
        .font-size(@ToggleIconSize);
      }
    }
  }

  .p-panel .p-panel-content, .p-panel .p-panel-header, .p-panel .p-panel-footer {
    .border-width(@BorderTopWidth, 0, @BorderBottomWidth, 0);
    .border-style(@BorderTopStyle, none, @BorderBottomStyle, none);
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
  }

  &:not(.no-header) .p-panel .p-panel-header {
    border-top: none;
  }

  &:not(.no-footer) .p-panel .p-panel-footer {
    border-bottom: none;
  }

  .collapsed {
    .p-panel .p-panel-header {
      border-bottom-right-radius: @BorderRadiusBottomRight;
      border-bottom-left-radius: @BorderRadiusBottomLeft;
    }
  }

  .no-footer {
    .p-panel .p-panel-content {
      border-bottom-right-radius: @BorderRadiusBottomRight;
      border-bottom-left-radius: @BorderRadiusBottomLeft;
      /*border-bottom: @BorderBottomWidth @BorderBottomStyle @BorderBottomColor;*/
    }
  }

  .no-header {
    .p-panel .p-panel-content {
      border-top-right-radius: @BorderRadiusTopRight;
      border-top-left-radius: @BorderRadiusTopLeft;
      /*border-top: @BorderTopWidth @BorderTopStyle @BorderTopColor;*/
    }
  }
  /*.p-panel-icons {
    display: none !important;
  }*/
}

.panel-header-status-mixin () {

  .p-panel .p-panel-header {
    padding: @PaddingTop @PaddingRight @PaddingBottom @PaddingLeft;
    background: @BackgroundColor;
    color: @ForegroundColor;
    border-top-right-radius: @BorderRadiusTopRight;
    border-top-left-radius: @BorderRadiusTopLeft;
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);

    * {
      color: @ForegroundColor;
      font-size: @FontSize;
    }

    label {
      margin-bottom: 0;
    }

    > div:not(.p-panel-icons) {
      display: flex;
      width: 100%;
      align-items: center;
    }
  }

  .p-panel .p-panel-header .p-panel-title {
    font-weight: @FontWeight;
  }
}

.panel-body-status-mixin () {
  .p-panel .p-panel-content {
    padding: @PaddingTop @PaddingRight @PaddingBottom @PaddingLeft;
    background-color: @BackgroundColor;
    color: @ForegroundColor;
    border-top: 0 none;
    border-bottom: 0 none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    * {
      color: @ForegroundColor;
    }
  }
}

.panel-footer-status-mixin () {
  .p-panel .p-panel-footer {
    padding: @PaddingTop @PaddingRight @PaddingBottom @PaddingLeft;
    background: @BackgroundColor;
    color: @ForegroundColor;
    border-bottom-right-radius: @BorderRadiusBottomRight;
    border-bottom-left-radius: @BorderRadiusBottomLeft;
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);

    * {
      color: @ForegroundColor;
    }
  }
}
